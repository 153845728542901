html { 
    background: url("background.jpeg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #FFFFFF;
  }
a{ text-decoration: none;}
hr{
    border: 1px solid rgba($color: #FFF, $alpha: 0.1);
  }

.header{        
    background-color: rgba($color: #000, $alpha: 0.1);
    border-bottom: 2px solid rgba($color: #000, $alpha: 0.2) ;
    .header-container{
        max-width: 1200px;
        margin: 0 auto; 
        padding: 10px 0;   
        display: flex;
        .logo-container{
            img{
                width: 30px;
            }
            a{
                color: #FFFFFF;
            }            
            display:inline-block;
            font-size: 2em;
            font-weight: bold;
            color: #FFFFFF;
        }
        .my-cart{
            margin-left: auto;
            display: flex;
            img{
                width: 25px;
                display: inline-block;
            }            
            span{
                font-size: 0.7em;
                color: #FFFFFF;
                text-align: center;
                background-color: #ff0000;
                border-radius: 10px;
                width: 15px;
                height: 15px;  
                padding: 0 3px 2px 3px;              
            }
        }    
    }
}

.container{
    max-width: 1200px;
    margin: 0 auto; 
    padding: 10px 0;
    position: relative;
}

.home{
    .subtitle{
        text-align: center;        
        max-width: 600px;
        margin: 0 auto;
        padding: 50px 0;
        color: #FFFFFF;
        h2{
            font-size: 2em;
        }
    }
    .search-bar{
        width: 100%;
        background: rgba($color: #000, $alpha: 0.1);
        border: 2px solid rgba($color: #000, $alpha: 0.1);
        padding: 10px 15px;
        border-radius: 15px;
        display: flex;
        .search-input{
            flex: 1;
            color: #FFFFFF;
            label{
                font-size: 0.8em;
                display: block;                
            }
            input{
                font-size: 1.2em;
                background: transparent;
                border: none;
                color: #FFFFFF;
            }
            input:focus {
                outline: none;
            }
            input::placeholder{
                color: #FFFFFF;
            }
            select{
                font-size: 1.4em;
                background: transparent;
                border: none;
                color: #FFFFFF;
                padding: 0 1em 0 0;
                margin: 0;                
                font-family: inherit;
                font-size: inherit;
                cursor: inherit;
                line-height: inherit;
            }
        }
        .search-action button{
            width: 200px;
            flex: 1;
            font-size: 1.2em;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(180deg, rgb(52, 180, 255) 0%, rgba(0,212,255,1) 100%); 
            border: 2px solid rgb(28, 177, 207);    
            color: #FFFFFF;    
        }
        .search-action button:hover{
            background: linear-gradient(180deg, rgb(49, 172, 243) 0%, rgb(0, 194, 233) 100%); 
            cursor: pointer;
        }
    }
    .search-results{        
        .no-results, .ok-results{
            text-align: center;
            padding: 50px 0;                     
        }     
        .ok-results{
            .list-results{
                display: flex;
                flex-wrap: wrap;
            }
        }   
    }
}

.sucess-alert{
    background: linear-gradient(0deg,rgba(54,224,42,1) 0%, rgba(38,182,77,1) 100%);
    border: 3px solid gba(38,182,77,1);
    margin: 0 auto;
    position: absolute;
    width: 300px;
    padding: 10px;
    border-radius: 10px;
    top: 10px;
    right: 0;
    font-size: 0.9em;
}


.card-flight{
    width: 300px;
    background: rgba($color: #000, $alpha: 0.1);    
    border-radius: 15px;
    margin: 0 10px 15px 10px;    
        
    .itinerary{
        background: rgba($color: #000, $alpha: 0.2);
        border-radius: 12px 12px 0 0;
        display: flex;        
        padding: 10px;
        .location{
            .time{                
                font-weight: bold;
                font-size: 1.2em;
                span{
                    font-size: 0.6em;
                    text-transform: uppercase;

                }
            } 
            .place{
                text-align: right;
            }
        }
        .to{
            margin-left: auto;
        }
    }    
    .recomendations{
        p{
            font-size: 0.8em;
            padding: 0 20px;
        }
    }
    .price{
        background: rgba($color: #000, $alpha: 0.1);        
        padding: 10px;
        font-size: 1.3em;
        font-weight: bold;
        text-align: right;
        span{
            display: block;
            font-size: 0.6em;
        }
    }
    .pick-flight{
        border-top: 2px solid rgba($color: #000, $alpha: 0.1);    
        button{
            background: linear-gradient(0deg,rgba(54,224,42,1) 0%, rgba(38,182,77,1) 100%);
            font-size: 1.2em;
            width: 100%;
            border: none;
            border-radius: 0 0 15px 15px;
            color: #FFFFFF;
            padding: 20px 0;
            cursor: pointer;
        }
        button:hover{
            background: linear-gradient(0deg, rgba(49,203,38,1) 0%, rgba(33,159,67,1) 100%);
        }
    }
}
.card-flight:hover{
    background: rgba($color: #000, $alpha: 0.15);
}



.cart-container{
    display: flex;
    align-items: flex-start;
    .bookings{
        background: rgba($color: #000, $alpha: 0.1);
        width: 100%;
        margin-right: 20px;
        padding: 15px 15px 50px 15px;
        border-radius: 15px;
        .no-data{
            text-align: center;
            padding: 20px 0;
        }
    }
    .summary{
        width: 450px;
        background: rgba($color: #000, $alpha: 0.1);
        border-radius: 15px;
        padding: 0 15px 15px;        
        .subtotal, .total{
            display: flex;
            padding: 5px 0;
            .amount{
                margin-left: auto;
            }
        }
        .total{
            font-size: 1.2em;
            font-weight: bold;
        }
        .reservation{
            margin-top: 10px;
            background: linear-gradient(0deg,rgba(54,224,42,1) 0%, rgba(38,182,77,1) 100%);
            font-size: 1.2em;
            width: 100%;
            border: none;
            border-radius: 10px;
            color: #FFFFFF;
            padding: 15px 0;
            cursor: pointer;
            width: 100%;
            display: block;
            text-align: center;
        }
        .reservation:hover{
            background: linear-gradient(0deg, rgba(49,203,38,1) 0%, rgba(33,159,67,1) 100%);
        }
    }
}


.flight-item{
    display: flex;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba($color: #FFF, $alpha: 0.1);
    padding-bottom: 10px;
    .data-flight{
        display: flex;
        .location{
            padding-right: 50px;
            .time{                
                font-weight: bold;
                font-size: 1.2em;                
                span{
                    font-size: 0.6em;
                    text-transform: uppercase;
                }
            } 
            .place{
                text-align: right;
                font-size: 0.8em;
            }
        }
    }
    .data-amount, .data-prices, .data-cancel{
        margin-left: auto;
    }
    .data-amount{
        font-size: 1.2em;
        font-weight: bold;
        span{
            font-size: 0.6em;            
        }
    }
    .data-prices{        
        font-weight: bold;
        .price span{
            display: block;
            font-size: 0.8em;
            text-align: right;
        }
    }
    .data-cancel{
        button{
            background: rgba($color: #000, $alpha: 0.05);
            opacity: 0.5;
            border-radius: 5px;            
            border: none;
            padding: 2px 4px;
            margin-top: 5px;
            cursor: pointer;
            img{
                margin-top: 2px;
                width: 15px;                
            }
        }
        button:hover{
            opacity: 1;
        }
    }
}

.form-reservation{   
    width: 100%;     
    .name-info,.address-info, .mail-info{
        width: 100%;  
                      
    }
    .field-cont{
        margin-right: 20px;
        margin-bottom: 15px;
         
    }
    .field-cont label{
        font-size: 0.8em;
        display: block;
         
    }
    
    .field-cont .field{
        font-size: 1.2em;
        background: transparent;
        border: none;        
        color: #FFFFFF;
        border-bottom: 1px solid rgba($color: #FFF, $alpha: 0.5);
        display: block;        
        width: 100%;
    }
    .field-cont .field:focus {
        outline: none;
    }
    .field-cont .field::placeholder{
        color: #FFFFFF;
    }
}

@media screen and (max-width:520px){
	body {
		font-size:80%; 
	} 
    .card-flight{
        width: 100%;
    }
    .home {
        padding: 20px;
        .search-bar{        
            display:block;        
            width: auto;
            font-size: 1.5em;
            .search-input{
                width: 100%;
                display: block;
                margin-bottom: 10px;
            }
            .search-action button{
                width: 100%;
            }
        }
    }
    .cart-container{
        display: block;
        padding: 15px;
        .bookings{
            width: auto;
            margin-right: 0;
        }
        .summary{
            width: auto;
        }
    }
}